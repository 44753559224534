import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';

declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({ 
  selector: 'app-ticket-view-thread',
  templateUrl: './ticket-view-thread.component.html',
  styleUrls: ['./ticket-view-thread.component.css']
})
export class TicketViewThreadComponent implements OnInit {
//   public tools: object = {
//     items: ['Undo', 'Redo', '|',
//         'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
//         'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
//         'SubScript', 'SuperScript', '|',
//         'LowerCase', 'UpperCase', '|',
//         'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
//         'Indent', 'Outdent', '|', 'CreateLink',
//         'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen']
// };
  // public Editor = ClassicEditor;
  ticket_t;
  admin_id;
  user_id;
  access_token;
  ticket_replies;
  tick_subject;
  tick_from;
  ticket_status;
  first_letter;
  department;
  tick_time;
  ticket_to;
  rep_to;
  status;
  mainCont;

  closed=false;
  first_res_time;
  closed_time;
  dept_list;
  constructor(private serverService: ServerService, private router:Router,private route: ActivatedRoute, private sanitizer: DomSanitizer,private tinymce:EditorModule) {  
    
    this.ticket_t = this.route.snapshot.queryParamMap.get('ticket_id');
  }
  ngOnInit() {
  //const content = editor.getContent();

    this.admin_id = localStorage.getItem('admin_id');
    this.user_id = localStorage.getItem('userId');
    this.access_token = localStorage.getItem('access_token');
    this.getTicketDetails(this.ticket_t);
    this.dept_settings();
  }
  dept_settings(){
    let access_token: any=localStorage.getItem('access_token');
  
    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_dept_settings","user_id":"'+this.admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){       
        this.dept_list = response.result.data;
        console.log(this.dept_list);
      } else {
       
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  

  getTicketDetails(tick_id){
    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"viewExternalTicket","ticket_id":"'+tick_id+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status=="true"){
        this.ticket_replies = response.tick_options;
        this.tick_subject = response.tick_options[0].subject;
        this.tick_from = response.tick_options[0].ticket_created_by;
        this.ticket_status = response.tick_options[0].ticket_status;
        $('#ticket_status').val(this.ticket_status);
        this.first_letter = response.tick_options[0].first_letter;
        this.department =  response.tick_options[0].department;
        $('#dept_status').val(this.department);
        this.tick_time = response.tick_options[0].ticket_created_at;
        this.ticket_to = response.tick_options[0].ticket_to;
        this.mainCont = response.tick_options[0].ticket_message;
        this.ticket_t = atob(this.ticket_t);
        this.status = response.status_options;
// this.getdeptuser(this.department);

        if(response.closed_at==""||response.closed_at==null){
          this.closed=false;
        }else{
          this.closed=true;
        }
        
        this.first_res_time=response.first_res_time;
        this.closed_time=response.closed_at;
      } 
    }, 
    (error)=>{
      console.log(error);
    });
  }

  //  toggleClass(){
  //   var x = document.getElementById("replyPanel");
  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }
  // }

  getdeptuser(id){
    // alert(id);
    let access_token: any=localStorage.getItem('access_token');
    let api_req:any = '{"operation":"ticket", "moduleType": "ticket", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_department","dept_id":"'+id+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        var agent_data = response.result.data;
        console.log(this.dept_list);
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }


  toggleClass(id,to){
   var x = document.getElementById("replyPanel_"+id);
   var y = document.getElementById("replyPanelall_"+id);
    y.style.display = "none";
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  }

  toggleClassreplyall(id){
    var x = document.getElementById("replyPanelall_"+id);
    // var y = document.getElementById("replyPanel_"+id);
    // y.style.display = "none";
  //  $('#'+y).hide();
  // alert(x);
   $('#replyPanelall_'+id).show();

    // if (x.style.display === "none") {
    //   x.style.display = "block";
    // } else {
    //   x.style.display = "none";
    // }
    
  }

  changeMyStatus(){

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, close it!'
    }).then((result) => {
      if (result.value) {

    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_status","status_id":"9","ticket_id":"'+this.ticket_t+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
        Swal.fire(
          'Deleted!',
          'success'
        );
        this.closed=true;
        this.ticket_t=btoa(this.ticket_t);
        this.getTicketDetails(this.ticket_t);
      } 
    }, 
    (error)=>{
      console.log(error);
    });
  }
})
}



// replyMesssage(msg_id,to){
//   var msg_id = $('#message_'+msg_id).val();
//   let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"replyMessage","message":"'+msg_id+'","ticket_id":"'+this.ticket_t+'","to":"'+to+'"}}';
//   this.serverService.sendServer(api_req).subscribe((response:any) => {
//     if(response.status==true){
//       Swal.fire(
//         'Deleted!',
//         'success'
//       );
//       this.getTicketDetails(this.ticket_t);
//     } 
//   }, 
//   (error)=>{
//     console.log(error);
//   });

// }


replyMessage(msg_id,to,extra){
  let access_token: any=localStorage.getItem('access_token');
  let user_id: any =  localStorage.getItem('userId'); 
  let api_req:any = new Object();
  let agent_req:any = new Object();
 
  var formData = new FormData();

  // var fileName = $('#up_files_'+msg_id).val();
  // alert(fileName); return false;

  msg_id = msg_id+extra;


  // if((<HTMLInputElement>document.getElementById('up_files_'+msg_id)).value != null){
  //   var totalfiles = (<HTMLInputElement>document.getElementById('up_files_'+msg_id)).files.length;
  //   for (var index = 0; index < totalfiles; index++) {
  //     formData.append("up_files[]", (<HTMLInputElement>document.getElementById('up_files_'+msg_id)).files[index]);

  //   }
  // }

  var to = $('#email_address_'+msg_id).val();

   //var msg_id = $('#editor').val();
  var msg_id = $('#reply_message_'+msg_id).val();
  // alert(to);
  
  // var myContent = tinymce.get("myTextarea").getContent();
// alert(to1);
// return false;
  if(msg_id == '' || msg_id == undefined){
    iziToast.error({
      message: "Please Enter the Message",
      position: 'topRight'
  });
  return false;
  }
  //agent_req.to=to;

  formData.append('operation', 'ticket');
  formData.append('moduleType', 'ticket');
  formData.append('api_type', 'web');
  formData.append('access_token', access_token);
  formData.append('user_id', user_id);
  formData.append('action', 'replyTicketMessage');
  formData.append('message', msg_id);
  formData.append('ticket_id', this.ticket_t);
  formData.append('to', to);
  // Read selected files

  Swal.fire({
    title: 'Please Wait',
    allowEscapeKey: false,
    allowOutsideClick: false,
  //  background: '#19191a',
    showConfirmButton: false,
    onOpen: ()=>{
        Swal.showLoading();
    }
  });
 

  
  $.ajax({    
    url:"https://rak.mconnectapps.com/api/v1.0/index_new.php",  
    type : 'POST',
    data : formData,
    processData: false,  // tell jQuery not to process the data
    contentType: false, 
    success:function(data){ 
      Swal.close();
      this.parsed_data = JSON.parse(data);
      console.log(this.parsed_data );
      if(this.parsed_data.status == 'true'){    
        iziToast.success({
          message: "Replied Successfully",
          position: 'topRight'
      });
      $("#refresh_page").click();      
      } else {
        iziToast.error({
          message: "Sorry, Some Error Occured",
          position: 'topRight'
      });
      }
    }  
});  

  }



  changeMyStatusmain(ticket_id, status){
    let api_req:any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"'+this.access_token+'", "element_data":{"action":"onchange_status","status_id":"'+status+'","ticket_id":"'+ticket_id+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.status==true){
      this.getTicketDetails(this.ticket_t);
      } 
    }, 
    (error)=>{
      console.log(error);
    });
  
    }
    refresh(){
      //alert(this.ticket_t);
      this.ticket_t=btoa(this.ticket_t);
    this.getTicketDetails(this.ticket_t);
    }

}
